import {
    AsyncForm,
    BootstrapIcon,
    Button,
    Checkbox,
    LoadingOverlay,
    PasswordRequirements,
    PasswordRequirementsCheck,
    RequestTools,
    Select,
    TextInput,
    Toast,
    ValidationTools,
} from '@b4valuenet/ui-react';
import { ChangeEvent, useEffect, useState } from 'react';

import { Country } from '../../../../models/Country';
import { GenericActionResponse } from '../../../../models/GenericActionResponse';
import { Language } from '../../../../models/Language';
import LocaleService from '../../../../services/LocaleService';
import OutboxBanner from '../../components/OutboxBanner/OutboxBanner';
import PasswordService from '../../../../services/PasswordService';
import { RegisterClientFields } from '../../../../models/RegisterClientFields';
import { RegisterClientResultType } from '../../../../models/RegisterClientResultType';
import { RegisterCompanyData } from '../../../../models/RegisterCompanyData';
import { RegisterUserData } from '../../../../models/RegisterUserData';
import RegistrationService from '../../../../services/RegistrationService';
import { useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

export default function Register() {
    const { t } = useTranslation();

    const userLanguage = useAppSelector((state) => state.userSettings.lang);
    const appSettings = useAppSelector((state) => state.app.settings);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);

    const [fieldErrorTarget, setFieldErrorTarget] =
        useState<RegisterClientFields | null>(null);
    const [fieldError, setFieldError] = useState<string | null>(null);

    const [companyData, setCompanyData] = useState<RegisterCompanyData>({
        name: '',
        street: '',
        zip: '',
        city: '',
        language: userLanguage || undefined,
        country: undefined,
        vatId: '',
        taxId: '',
    });
    const [userData, setUserData] = useState<RegisterUserData>({
        firstName: '',
        lastName: '',
        language: userLanguage || undefined,
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] =
        useState<boolean>(false);

    const [formSended, setFormSended] = useState<boolean>(false);

    const [availableLanguagesList, setAvailableLanguagesList] = useState<
        Language[] | null
    >();
    const [countriesList, setCountriesList] = useState<Country[] | null>();
    const [passwordRequirements, setPasswordRequirements] =
        useState<PasswordRequirements | null>(null);

    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    let vatIdRequired = companyData.taxId.length === 0;
    let taxIdRequired = companyData.vatId.length === 0;

    useEffect(() => {
        let companyDataValid: boolean =
            companyData.name.length > 0 &&
            companyData.language != null &&
            companyData.language.length > 0 &&
            companyData.country != null &&
            companyData.country.length > 0 &&
            (companyData.vatId.length > 0 || companyData.taxId.length > 0);

        let userDataValid =
            userData.firstName.length > 0 &&
            userData.lastName.length > 0 &&
            userData.language != null &&
            userData.language?.length > 0 &&
            userData.email.length > 0 &&
            passwordRequirements !== null &&
            ValidationTools.isPasswordMatchRequirements(
                userData.password,
                passwordRequirements
            ) &&
            userData.password === userData.confirmPassword;

        setIsFormValid(companyDataValid && userDataValid && termsAccepted);
    }, [companyData, userData, termsAccepted, passwordRequirements]);

    async function handleSubmit() {
        setFormSended(true);
        setError(null);
        setFieldError(null);
        setFieldErrorTarget(null);

        if (isFormValid) {
            setIsLoading(true);

            try {
                await RegistrationService.register(companyData, userData);
                setError(null);
                setSuccess(true);
            } catch (error: any) {
                if (error.response && error.response.data !== null) {
                    const response: GenericActionResponse = error.response.data;

                    if (
                        response.data ===
                        RegisterClientResultType.VatIdIncorrect
                    ) {
                        setFieldError(response.message);
                        setFieldErrorTarget(RegisterClientFields.VatId);
                        setIsLoading(false);
                        return;
                    }

                    if (
                        response.data ===
                        RegisterClientResultType.UserNamePasswordPolicyViolation
                    ) {
                        setFieldError(response.message);
                        setFieldErrorTarget(RegisterClientFields.NewPassword);
                        setIsLoading(false);
                        return;
                    }

                    setError(response.message);
                } else {
                    let message = RequestTools.getApiErrorMessage(error);
                    setError(message);
                }
            }

            setIsLoading(false);
        }
    }

    function updateCompanyDataField(field: string, value: string) {
        let newCompanyData = { ...companyData } as any;
        newCompanyData[field] = value;
        setCompanyData(newCompanyData);
    }

    function updateUserDataField(field: string, value: string) {
        let newUserData = { ...userData } as any;
        newUserData[field] = value;
        setUserData(newUserData);
    }

    useEffect(() => {
        const fetchAvailableLanguages = async () => {
            try {
                let languages = await LocaleService.getLanguageList();
                setAvailableLanguagesList(languages);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        };

        const fetchCountries = async () => {
            try {
                let countries = await LocaleService.getCountriesList();
                setCountriesList(countries);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        };

        fetchAvailableLanguages();
        fetchCountries();
    }, []);

    useEffect(() => {
        const fetchPasswordRequirements = async () => {
            setIsLoading(true);

            try {
                let result = await PasswordService.getPasswordRequirements();

                if (result) {
                    setPasswordRequirements(result.data);
                } else {
                    Toast.error('Password requirements could not be load.');
                }

                setIsLoading(false);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);

                setIsLoading(false);
            }
        };

        fetchPasswordRequirements();
    }, []);

    useEffect(() => {
        const validatePasswordUpdateForm = () => {
            let newPasswordValid =
                passwordRequirements !== null
                    ? ValidationTools.isPasswordMatchRequirements(
                          userData.password,
                          passwordRequirements
                      )
                    : false;
            let confirmPasswordValid =
                userData.confirmPassword === userData.password;

            setIsPasswordValid(newPasswordValid);
            setIsConfirmPasswordValid(confirmPasswordValid);
        };

        validatePasswordUpdateForm();
    }, [userData.password, userData.confirmPassword, passwordRequirements]);

    const beautifyPasswordRequirements = (
        passwordRequirements: PasswordRequirements | null
    ): JSX.Element => {
        if (!passwordRequirements) {
            return <></>;
        }

        return (
            <PasswordRequirementsCheck
                translations={{
                    title: t('Password.Requirements.Text'),
                    minDigits: t('Password.MinDigits.Text', {
                        numberDigits: passwordRequirements.minimumNumberDigits,
                    }),
                    minLetters: t('Password.MinLetters.Text', {
                        numberLetters:
                            passwordRequirements.minimumNumberLetters,
                    }),
                    minSpecials: t('Password.MinSpecials.Text', {
                        numberSpecials:
                            passwordRequirements.minimumNumberSpecialCharacters,
                    }),
                    minLength: t('Password.MinLength.Text', {
                        minLength: passwordRequirements.minimumPasswordLength,
                    }),
                }}
                password={userData.password}
                requirements={passwordRequirements}
            />
        );
    };

    return (
        <div className={'auth-form-wrapper' + (!success ? ' scrollable' : '')}>
            {!success && (
                <>
                    <AsyncForm
                        className='auth-form big'
                        onSubmit={() => handleSubmit()}
                    >
                        <OutboxBanner />

                        <section>
                            <h2>{t('Registration.RegistrationCard.Text')}</h2>

                            <fieldset>
                                <legend>
                                    {t('Registration.CompanyDataTab.Text')}
                                </legend>

                                <div className='spaced-fields'>
                                    <TextInput
                                        type='text'
                                        required
                                        icon='building'
                                        value={companyData.name}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            updateCompanyDataField(
                                                'name',
                                                e.target.value
                                            )
                                        }
                                        isValid={
                                            !formSended ||
                                            companyData.name.length > 0
                                        }
                                        validationError={t(
                                            'ValidationMessage.Required.Text'
                                        )}
                                        placeholder={t(
                                            'Registration.CompanyName.Text'
                                        )}
                                        label={t(
                                            'Registration.CompanyName.Text'
                                        )}
                                        infoButtonEnabled={true}
                                        infoButtonTooltipText={t(
                                            'Registration.CompanyName.Help'
                                        )}
                                        infoButtonTitle={t(
                                            'Common.InputHelp.Text'
                                        )}
                                        infoButtonAriaLabel={t(
                                            'Common.InputHelp.Text'
                                        )}
                                    />
                                    <TextInput
                                        type='text'
                                        icon='signpost'
                                        value={companyData.street}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            updateCompanyDataField(
                                                'street',
                                                e.target.value
                                            )
                                        }
                                        placeholder={t(
                                            'Registration.Street.Text'
                                        )}
                                        label={t('Registration.Street.Text')}
                                        infoButtonEnabled={true}
                                        infoButtonTooltipText={t(
                                            'Registration.Street.Help'
                                        )}
                                        infoButtonTitle={t(
                                            'Common.InputHelp.Text'
                                        )}
                                        infoButtonAriaLabel={t(
                                            'Common.InputHelp.Text'
                                        )}
                                    />
                                    <div className='flex-row'>
                                        <TextInput
                                            type='text'
                                            icon='mailbox'
                                            value={companyData.zip}
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                updateCompanyDataField(
                                                    'zip',
                                                    e.target.value
                                                )
                                            }
                                            placeholder={t(
                                                'Registration.Zip.Text'
                                            )}
                                            label={t('Registration.Zip.Text')}
                                            infoButtonEnabled={true}
                                            infoButtonTooltipText={t(
                                                'Registration.Zip.Help'
                                            )}
                                            infoButtonTitle={t(
                                                'Common.InputHelp.Text'
                                            )}
                                            infoButtonAriaLabel={t(
                                                'Common.InputHelp.Text'
                                            )}
                                        />
                                        <TextInput
                                            type='text'
                                            icon='geo-alt'
                                            value={companyData.city}
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                updateCompanyDataField(
                                                    'city',
                                                    e.target.value
                                                )
                                            }
                                            placeholder={t(
                                                'Registration.City.Text'
                                            )}
                                            label={t('Registration.City.Text')}
                                            infoButtonEnabled={true}
                                            infoButtonTooltipText={t(
                                                'Registration.City.Help'
                                            )}
                                            infoButtonTitle={t(
                                                'Common.InputHelp.Text'
                                            )}
                                            infoButtonAriaLabel={t(
                                                'Common.InputHelp.Text'
                                            )}
                                        />
                                    </div>
                                    <Select
                                        icon='globe'
                                        value={companyData.language}
                                        onChange={(
                                            e: ChangeEvent<HTMLSelectElement>
                                        ) =>
                                            updateCompanyDataField(
                                                'language',
                                                e.target.value
                                            )
                                        }
                                        required={true}
                                        placeholder={t(
                                            'Registration.CompanyLanguage.Text'
                                        )}
                                        label={t(
                                            'Registration.CompanyLanguage.Text'
                                        )}
                                        isValid={ValidationTools.isRequiredFieldValueValid(
                                            companyData.language,
                                            formSended
                                        )}
                                        validationError={t(
                                            'ValidationMessage.Required.Text'
                                        )}
                                        infoButtonEnabled={true}
                                        infoButtonTooltipText={t(
                                            'Registration.CompanyLanguage.Help'
                                        )}
                                        infoButtonTitle={t(
                                            'Common.InputHelp.Text'
                                        )}
                                        infoButtonAriaLabel={t(
                                            'Common.InputHelp.Text'
                                        )}
                                    >
                                        {availableLanguagesList?.map(
                                            (language, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={language.code.toLowerCase()}
                                                    >
                                                        {language.text}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Select>
                                    <Select
                                        icon='pin-map'
                                        value={companyData.country}
                                        onChange={(
                                            e: ChangeEvent<HTMLSelectElement>
                                        ) =>
                                            updateCompanyDataField(
                                                'country',
                                                e.target.value
                                            )
                                        }
                                        placeholder={t(
                                            'Registration.Country.Text'
                                        )}
                                        required={true}
                                        label={t('Registration.Country.Text')}
                                        isValid={ValidationTools.isRequiredFieldValueValid(
                                            companyData.country,
                                            formSended
                                        )}
                                        validationError={t(
                                            'ValidationMessage.Required.Text'
                                        )}
                                        infoButtonEnabled={true}
                                        infoButtonTooltipText={t(
                                            'Registration.Country.Help'
                                        )}
                                        infoButtonTitle={t(
                                            'Common.InputHelp.Text'
                                        )}
                                        infoButtonAriaLabel={t(
                                            'Common.InputHelp.Text'
                                        )}
                                    >
                                        {countriesList?.map(
                                            (country, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={country.code.toLowerCase()}
                                                    >
                                                        {country.name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Select>
                                    <TextInput
                                        type='text'
                                        icon='bank'
                                        value={companyData.vatId}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            updateCompanyDataField(
                                                'vatId',
                                                e.target.value
                                            )
                                        }
                                        isValid={
                                            fieldErrorTarget !==
                                                RegisterClientFields.VatId &&
                                            (!formSended ||
                                                !vatIdRequired ||
                                                companyData.vatId.length > 0)
                                        }
                                        validationError={
                                            fieldErrorTarget ===
                                                RegisterClientFields.VatId &&
                                            fieldError !== null
                                                ? fieldError
                                                : t(
                                                      'ValidationMessage.VatIdOrTaxIdRequired.Text'
                                                  )
                                        }
                                        required={vatIdRequired}
                                        placeholder={t('Registration.UID.Text')}
                                        label={t('Registration.UID.Text')}
                                        infoButtonEnabled={true}
                                        infoButtonTooltipText={t(
                                            'Registration.UID.Help'
                                        )}
                                        infoButtonTitle={t(
                                            'Common.InputHelp.Text'
                                        )}
                                        infoButtonAriaLabel={t(
                                            'Common.InputHelp.Text'
                                        )}
                                    />
                                    <TextInput
                                        type='text'
                                        icon='bank'
                                        value={companyData.taxId}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            updateCompanyDataField(
                                                'taxId',
                                                e.target.value
                                            )
                                        }
                                        isValid={
                                            !formSended ||
                                            !taxIdRequired ||
                                            companyData.taxId.length > 0
                                        }
                                        validationError={t(
                                            'ValidationMessage.Required.Text'
                                        )}
                                        required={taxIdRequired}
                                        placeholder={t(
                                            'Registration.TaxId.Text'
                                        )}
                                        label={t('Registration.TaxId.Text')}
                                        infoButtonEnabled={true}
                                        infoButtonTooltipText={t(
                                            'Registration.TaxId.Help'
                                        )}
                                        infoButtonTitle={t(
                                            'Common.InputHelp.Text'
                                        )}
                                        infoButtonAriaLabel={t(
                                            'Common.InputHelp.Text'
                                        )}
                                    />
                                </div>
                            </fieldset>

                            <fieldset>
                                <legend>
                                    {t('Registration.UserDataTab.Text')}
                                </legend>

                                <div className='spaced-fields'>
                                    <div className='flex-row'>
                                        <TextInput
                                            type='text'
                                            icon='person'
                                            value={userData.firstName}
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                updateUserDataField(
                                                    'firstName',
                                                    e.target.value
                                                )
                                            }
                                            isValid={
                                                !formSended ||
                                                userData.firstName.length > 0
                                            }
                                            validationError={t(
                                                'ValidationMessage.Required.Text'
                                            )}
                                            required
                                            placeholder={t(
                                                'Registration.FirstName.Text'
                                            )}
                                            label={t(
                                                'Registration.FirstName.Text'
                                            )}
                                            infoButtonEnabled={true}
                                            infoButtonTooltipText={t(
                                                'Registration.FirstName.Help'
                                            )}
                                            infoButtonTitle={t(
                                                'Common.InputHelp.Text'
                                            )}
                                            infoButtonAriaLabel={t(
                                                'Common.InputHelp.Text'
                                            )}
                                        />
                                        <TextInput
                                            type='text'
                                            value={userData.lastName}
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                updateUserDataField(
                                                    'lastName',
                                                    e.target.value
                                                )
                                            }
                                            isValid={
                                                !formSended ||
                                                userData.lastName.length > 0
                                            }
                                            validationError={t(
                                                'ValidationMessage.Required.Text'
                                            )}
                                            required
                                            placeholder={t(
                                                'Registration.LastName.Text'
                                            )}
                                            label={t(
                                                'Registration.LastName.Text'
                                            )}
                                            infoButtonEnabled={true}
                                            infoButtonTooltipText={t(
                                                'Registration.LastName.Help'
                                            )}
                                            infoButtonTitle={t(
                                                'Common.InputHelp.Text'
                                            )}
                                            infoButtonAriaLabel={t(
                                                'Common.InputHelp.Text'
                                            )}
                                        />
                                    </div>
                                    <Select
                                        icon='globe'
                                        value={userData.language}
                                        onChange={(
                                            e: ChangeEvent<HTMLSelectElement>
                                        ) =>
                                            updateUserDataField(
                                                'language',
                                                e.target.value
                                            )
                                        }
                                        placeholder={t(
                                            'Registration.Language.Text'
                                        )}
                                        label={t('Registration.Language.Text')}
                                        isValid={ValidationTools.isRequiredFieldValueValid(
                                            userData.language,
                                            formSended
                                        )}
                                        required={true}
                                        validationError={t(
                                            'ValidationMessage.Required.Text'
                                        )}
                                        infoButtonEnabled={true}
                                        infoButtonTooltipText={t(
                                            'Registration.Language.Help'
                                        )}
                                        infoButtonTitle={t(
                                            'Common.InputHelp.Text'
                                        )}
                                        infoButtonAriaLabel={t(
                                            'Common.InputHelp.Text'
                                        )}
                                    >
                                        {availableLanguagesList?.map(
                                            (language, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={language.code.toLowerCase()}
                                                    >
                                                        {language.text}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Select>
                                    <TextInput
                                        type='text'
                                        autoComplete='email'
                                        icon='envelope'
                                        value={userData.email}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            updateUserDataField(
                                                'email',
                                                e.target.value
                                            )
                                        }
                                        isValid={
                                            !formSended ||
                                            ValidationTools.isEmailValid(
                                                userData.email
                                            )
                                        }
                                        validationError={
                                            userData.email.length === 0
                                                ? t(
                                                      'ValidationMessage.Required.Text'
                                                  )
                                                : t(
                                                      'ValidationMessage.EmailInvalid.Text'
                                                  )
                                        }
                                        required
                                        placeholder={t(
                                            'Registration.Email.Text'
                                        )}
                                        label={t('Registration.Email.Text')}
                                        infoButtonEnabled={true}
                                        infoButtonTooltipText={t(
                                            'Registration.UserEmail.Help'
                                        )}
                                        infoButtonTitle={t(
                                            'Common.InputHelp.Text'
                                        )}
                                        infoButtonAriaLabel={t(
                                            'Common.InputHelp.Text'
                                        )}
                                    />
                                    {beautifyPasswordRequirements(
                                        passwordRequirements
                                    )}
                                    <TextInput
                                        icon='key'
                                        type='password'
                                        value={userData.password}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            updateUserDataField(
                                                'password',
                                                e.target.value
                                            )
                                        }
                                        isValid={
                                            fieldErrorTarget !==
                                                RegisterClientFields.NewPassword &&
                                            ((!formSended &&
                                                userData.password.length ===
                                                    0) ||
                                                isPasswordValid)
                                        }
                                        validationError={
                                            fieldErrorTarget ===
                                                RegisterClientFields.NewPassword &&
                                            fieldError !== null
                                                ? fieldError
                                                : userData.password.length <
                                                  (passwordRequirements?.minimumPasswordLength ??
                                                      0)
                                                ? t(
                                                      'ValidationMessage.MinLength.Text',
                                                      {
                                                          minLength:
                                                              passwordRequirements?.minimumPasswordLength ??
                                                              1,
                                                      }
                                                  )
                                                : t(
                                                      'ValidationMessage.MeetRequirements.Text'
                                                  )
                                        }
                                        required
                                        placeholder={t(
                                            'Registration.Password.Text'
                                        )}
                                        label={t('Registration.Password.Text')}
                                        infoButtonEnabled={true}
                                        infoButtonTooltipText={t(
                                            'ResetPassword.PasswordHelpTitle.Text'
                                        )}
                                        infoButtonTitle={t(
                                            'Common.InputHelp.Text'
                                        )}
                                        infoButtonAriaLabel={t(
                                            'Common.InputHelp.Text'
                                        )}
                                    />
                                    <TextInput
                                        icon='key'
                                        type='password'
                                        value={userData.confirmPassword}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                            updateUserDataField(
                                                'confirmPassword',
                                                e.target.value
                                            )
                                        }
                                        isValid={isConfirmPasswordValid}
                                        validationError={
                                            userData.confirmPassword.length ===
                                            0
                                                ? t(
                                                      'ValidationMessage.Required.Text'
                                                  )
                                                : t(
                                                      'ValidationMessage.PasswordNotEqual.Text'
                                                  )
                                        }
                                        required
                                        placeholder={t(
                                            'Registration.ConfirmedPassword.Text'
                                        )}
                                        label={t(
                                            'Registration.ConfirmedPassword.Text'
                                        )}
                                        infoButtonEnabled={true}
                                        infoButtonTooltipText={t(
                                            'Registration.ConfirmedPassword.Help'
                                        )}
                                        infoButtonTitle={t(
                                            'Common.InputHelp.Text'
                                        )}
                                        infoButtonAriaLabel={t(
                                            'Common.InputHelp.Text'
                                        )}
                                    />
                                </div>
                            </fieldset>

                            {appSettings?.providerSettings
                                .dataProtectionLink && (
                                <p>
                                    {t('Registration.DataProtection.Text')}{' '}
                                    <a
                                        href={
                                            appSettings?.providerSettings
                                                .dataProtectionLink
                                        }
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        [
                                        {t('Common.DataProtection.Text')}
                                        ]
                                    </a>{' '}
                                    {t('Registration.DataProtection3.Text')}
                                </p>
                            )}
                            <Checkbox
                                checked={termsAccepted}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setTermsAccepted(e.target.checked)
                                }
                                title={t(
                                    'Registration.TermsAndConditions.Text'
                                )}
                                required
                                isValid={!formSended || termsAccepted}
                                validationError={t(
                                    'ValidationMessage.AcceptTermsAndConditions.Text'
                                )}
                            />
                            <p>
                                <a
                                    href={
                                        appSettings?.providerSettings
                                            .termsOfUseLink
                                    }
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {t(
                                        'Registration.TermsAndConditionsLinkText.Text'
                                    )}
                                </a>
                            </p>

                            <div aria-live='polite'>
                                {error && <p className='error'>{error}</p>}
                            </div>

                            <Button
                                type='submit'
                                disabled={!isFormValid}
                                primary={true}
                                className='auth-action-button'
                                text={t('Registration.RegisterBtn.Text')}
                            />

                            <div className='separator'></div>

                            <Button
                                isLink={true}
                                text={t('Registration.CancelBtn.Text')}
                                linkTo='/auth/login'
                                className='auth-action-button'
                            />
                        </section>
                    </AsyncForm>
                </>
            )}

            {success && (
                <div className='auth-form'>
                    <OutboxBanner />

                    <section>
                        <h2>{t('Registration.SuccessDialogTitle.Text')}</h2>

                        <p className='success'>
                            <BootstrapIcon icon='check-circle-fill' />
                            {t('Registration.SuccessInformation.Text')}
                        </p>
                        <p></p>

                        <Button
                            isLink={true}
                            linkTo={'/auth/login'}
                            className='auth-action-button'
                            type='button'
                            primary={false}
                            text={t('Registration.ForwardToLogin.Text')}
                        />
                    </section>
                </div>
            )}
            <LoadingOverlay
                loadingMessage={t('Common.LoadingMessage.Text')}
                active={isLoading}
                isGlobal={true}
            />
        </div>
    );
}
