import './XRechnungPage.scss';

import {
    AnimationTools,
    BootstrapIcon,
    Button,
    ITab,
    LoadingOverlay,
    RequestTools,
    TabbedView,
    Toast,
    findAndFocusModalCallerElement,
    useWindowSize,
} from '@b4valuenet/ui-react';
import { createRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DownloadInvoiceModal, {
    DownloadInvoiceModelResponse,
} from './components/DownloadInvoiceModal';

import { useTranslation } from 'react-i18next';
import { AmountGroupData } from '../../../../models/AmountGroupData';
import { Country } from '../../../../models/Country';
import { KeyValuePair } from '../../../../models/KeyValuePair';
import { PaymentMeansCode } from '../../../../models/PaymentInfo';
import { XRechnung } from '../../../../models/XRechnung';
import { XRechnungMessageType } from '../../../../models/XRechnungMessageType';
import { useAppSelector } from '../../../../redux/hooks';
import LocaleService from '../../../../services/LocaleService';
import MessagesService from '../../../../services/MessagesService';
import XRechnungService from '../../../../services/XRechnungService';
import XRechnungPagesValidator from './XRechnungPagesValidator';
import SchematronValidationErrorDialog from './pages/SchematronValidationErrorDialog';
import { XRechnungAttachments } from './pages/XRechnungAttachments/XRechnungAttachments';
import { XRechnungBuyer } from './pages/XRechnungBuyer/XRechnungBuyer';
import { XRechnungInvoiceData } from './pages/XRechnungInvoiceData/XRechnungInvoiceData';
import { XRechnungInvoiceLineItems } from './pages/XRechnungInvoiceLineItems/XRechnungInvoiceLineItems';
import { XRechnungPaymentMeans } from './pages/XRechnungPaymentMeans/XRechnungPaymentMeans';
import { XRechnungPaymentTotals } from './pages/XRechnungPaymentTotals/XRechnungPaymentTotals';
import { XRechnungReferences } from './pages/XRechnungReferences/XRechnungReferences';
import { XRechnungSeller } from './pages/XRechnungSeller/XRechnungSeller';
import { XRechnungSummary } from './pages/XRechnungSummary/XRechnungSummary';
import XRechnungBuyerValidators from './validators/BuyerValidators';
import XRechnungSellerValidators from './validators/SellerValidators';

export interface IXRechnungSubPage {
    isFormSubmitted: boolean;
    unitOptions: KeyValuePair[];
    taxCategories: KeyValuePair[];
    messageTypes: XRechnungMessageType[];
    countriesList?: Country[] | null;
    data: XRechnung | null;
    updateData: (callable: (prevState: XRechnung) => XRechnung | null) => void;
}

export function setTempValue(name: string, value: any) {
    (window as any).xrechnungTemp[name] = value;
}

export function getTempValue(name: string) {
    return (window as any).xrechnungTemp[name] ?? undefined;
}

export function XRechnungPage() {
    const navigate = useNavigate();
    const windowSize = useWindowSize();

    let params = useParams();
    let id = params.id !== undefined ? +params.id : null;
    let tab = params.tab !== undefined ? params.tab : null;

    useEffect(() => {
        (window as any).xrechnungTemp = {};
    }, [id]);

    let ref = createRef<HTMLDivElement>();

    const [activeTab, setActiveTab] = useState<string | number>(
        tab ?? 'invoice-data'
    );

    const { t } = useTranslation();
    const appSettings = useAppSelector((state) => state.app.settings);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [invoiceNotFound, setInvoiceNotFound] = useState<boolean>(false);

    const [data, setData] = useState<XRechnung | null>(null);
    const [initialData, setInitialData] = useState<XRechnung | null>(null);

    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

    const [isDownloadInvoiceModalActive, setIsDownloadInvoiceModalActive] =
        useState<boolean>(false);

    const [modalCallerElement, setModalCallerElement] =
        useState<HTMLButtonElement | null>(null);

    const [unitOptions, setUnitOptions] = useState<KeyValuePair[]>([]);
    const [taxCategories, setTaxCategories] = useState<KeyValuePair[]>([]);
    const [messageTypes, setMessageTypes] = useState<XRechnungMessageType[]>(
        []
    );
    const [countriesList, setCountriesList] = useState<Country[] | null>();

    const [schematronErrorsDialogActive, setSchematronErrorsDialogActive] =
        useState<boolean>(false);
    const [schematronErrors, setSchematronErrors] = useState<string[]>([]);

    function safeUpdateData(
        callable: (prevState: XRechnung) => XRechnung | null
    ) {
        setData((prevState) => {
            let prevData = prevState ?? data ?? ({} as any);
            return callable(prevData);
        });
    }

    useEffect(() => {
        setActiveTab(tab ?? 'invoice-data');
    }, [tab]);

    useEffect(() => {
        if (!data || !countriesList) return;

        if (!data?.receiverData.receiverAddressCountry) {
            safeUpdateData((prevState) => ({
                ...prevState,
                receiverData: {
                    ...prevState.receiverData,
                    receiverAddressCountry: countriesList[0].code ?? undefined,
                },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countriesList]);

    useEffect(() => {
        if (data) {
            if (
                data.documentTotalsData.totalNetAmount === null ||
                data.documentTotalsData.totalNetAmount === undefined
            ) {
                return safeUpdateData((prevState) => ({
                    ...prevState,
                    documentTotalsData: {
                        ...prevState.documentTotalsData,
                        totalNetAmount: undefined,
                    },
                }));
            }

            let sum1 =
                (data.documentTotalsData.amountsItemAmount || 0) +
                data.documentTotalsData.sumCharges -
                data.documentTotalsData.sumAllowance;

            sum1 = Math.round(sum1 * 100) / 100;

            if (!isNaN(sum1)) {
                safeUpdateData((prevState) => ({
                    ...prevState,
                    documentTotalsData: {
                        ...prevState.documentTotalsData,
                        totalNetAmount: sum1,
                    },
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data?.documentTotalsData?.amountsItemAmount,
        data?.documentTotalsData?.sumCharges,
        data?.documentTotalsData?.sumAllowance,
    ]);

    useEffect(() => {
        if (!data) return;

        let sumPositionsNetAmount = data.positions.reduce(
            (accumulator, position) => {
                return accumulator + (position.value || 0);
            },
            0
        );

        let sumVatAmount = data.documentTotalsData.amountGroups.reduce(
            (accumulator, object) => {
                return accumulator + (object.vatAmount || 0);
            },
            0
        );

        sumVatAmount = Math.round(sumVatAmount * 100) / 100;
        safeUpdateData((prevState) => ({
            ...prevState,
            documentTotalsData: {
                ...prevState.documentTotalsData,
                amountsItemAmount: sumPositionsNetAmount,
                totalVatAmount: sumVatAmount,
            },
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.documentTotalsData?.amountGroups]);

    useEffect(() => {
        if (data) {
            if (
                data.documentTotalsData.total === null ||
                data.documentTotalsData.total === undefined
            ) {
                return safeUpdateData((prevState) => ({
                    ...data,
                    documentTotalsData: {
                        ...data.documentTotalsData,
                        total: undefined,
                    },
                }));
            }

            let sum2 =
                (data.documentTotalsData.totalNetAmount || 0) +
                (data.documentTotalsData.totalVatAmount || 0);

            sum2 = Math.round(sum2 * 100) / 100;

            if (!isNaN(sum2)) {
                safeUpdateData((prevState) => ({
                    ...data,
                    documentTotalsData: {
                        ...data.documentTotalsData,
                        total: sum2,
                    },
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data?.documentTotalsData?.totalNetAmount,
        data?.documentTotalsData?.totalVatAmount,
    ]);

    // Amount due for payment
    useEffect(() => {
        if (data) {
            if (
                data.documentTotalsData.amount === null ||
                data.documentTotalsData.amount === undefined
            ) {
                return safeUpdateData((prevState) => ({
                    ...prevState,
                    documentTotalsData: {
                        ...prevState.documentTotalsData,
                        amount: undefined,
                    },
                }));
            }

            let sum3 =
                (data.documentTotalsData.total || 0) -
                (data.documentTotalsData.amountsPrepaidAmount || 0) +
                (data.documentTotalsData.amountsRoundingAmount || 0);

            if (!isNaN(sum3)) {
                safeUpdateData((prevState) => ({
                    ...prevState,
                    documentTotalsData: {
                        ...prevState.documentTotalsData,
                        amount: Math.round(sum3 * 100) / 100,
                    },
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data?.documentTotalsData?.total,
        data?.documentTotalsData?.amountsPrepaidAmount,
        data?.documentTotalsData?.amountsRoundingAmount,
    ]);

    // Amount Group Calculation
    useEffect(() => {
        if (!data) return;

        const newAmountGroups: AmountGroupData[] = [];
        const positionLines = data?.positions ?? [];

        for (const line of positionLines) {
            let insert = true;

            // find the matching vatrate and update netAmount + vatAmount
            for (const tmpAmount of newAmountGroups) {
                if (tmpAmount.vatRate === line.vatRate) {
                    tmpAmount.netAmount =
                        (tmpAmount.netAmount || 0) + (line.value || 0);

                    if (
                        tmpAmount.vatRate !== undefined &&
                        tmpAmount.vatRate < 0 &&
                        tmpAmount.vatRate >= -5
                    ) {
                        tmpAmount.vatAmount = 0;
                    } else {
                        tmpAmount.vatAmount =
                            ((tmpAmount.netAmount || 0) *
                                (tmpAmount.vatRate || 0)) /
                            100;

                        tmpAmount.vatAmount =
                            Math.round(tmpAmount.vatAmount * 100) / 100;
                    }
                    insert = false;
                }
            }

            if (insert) {
                let amount = 0;

                if (line.vatRate === undefined) {
                    amount = 0;
                } else if (line.vatRate <= -1 && line.vatRate >= -6) {
                    amount = 0;
                } else {
                    amount =
                        line.vatRate !== -1
                            ? ((line.value || 0) * (line.vatRate || 0)) / 100
                            : 0;

                    amount = Math.round(amount * 100) / 100;
                }

                newAmountGroups.push({
                    netAmount: line.value,
                    vatRate: line.vatRate || 0,
                    vatAmount: amount,
                });
            }
        }

        let sumAllowance =
            data?.documentTotalsData?.allowances instanceof Array
                ? data.documentTotalsData.allowances.reduce(
                      (accumulator, object) => {
                          return accumulator + (object.amount || 0);
                      },
                      0
                  )
                : 0;

        let sumCharges =
            data?.documentTotalsData?.agios instanceof Array
                ? data.documentTotalsData.agios.reduce(
                      (accumulator, object) => {
                          return accumulator + (object.amount || 0);
                      },
                      0
                  )
                : 0;

        const allowances = data?.documentTotalsData.allowances ?? [];
        for (const allowance of allowances) {
            if (allowance.vatRate === null || allowance.vatRate === undefined) {
                return;
            }

            let insert = true;
            let allowancesVatRate =
                allowance.vatRate <= 0 ? 0 : allowance.vatRate || 0;

            for (const amountGroup of newAmountGroups) {
                if (
                    allowance.amount &&
                    amountGroup.vatRate === allowance.vatRate
                ) {
                    amountGroup.netAmount =
                        (amountGroup.netAmount || 0) - (allowance.amount || 0);
                    if (allowance.vatRate < -1 && allowance.vatRate > -6) {
                        amountGroup.vatAmount = 0;
                    } else {
                        let amount =
                            allowance.amount * (allowance.vatRate / 100);

                        let totalAmountGroupVatAmount =
                            (amountGroup.vatAmount || 0) - amount;

                        totalAmountGroupVatAmount =
                            Math.round(totalAmountGroupVatAmount * 100) / 100;

                        amountGroup.vatAmount = totalAmountGroupVatAmount;
                    }
                    insert = false;
                }
            }

            if (insert) {
                let amount = 0;

                if (allowance.vatRate === undefined) {
                    amount = 0;
                } else if (allowance.vatRate <= 0) {
                    amount = 0;
                } else {
                    amount =
                        ((allowance.amount || 0) * (allowance.vatRate || 0)) /
                        100;

                    amount = Math.round(amount * 100) / 100;
                }

                newAmountGroups.push({
                    netAmount: (allowance.amount || 0) * -1,
                    vatRate: allowancesVatRate,
                    vatAmount: -amount,
                });
            }
        }

        const agios = data?.documentTotalsData.agios ?? [];
        for (const agio of agios) {
            if (agio.vatRate === null || agio.vatRate === undefined) {
                return;
            }

            let insert = true;
            let agioVatRate = agio.vatRate <= 0 ? 0 : agio.vatRate || 0;

            for (const amountGroup of newAmountGroups) {
                if (
                    agio.amount !== null &&
                    agio.amount !== undefined &&
                    amountGroup.vatRate === agioVatRate
                ) {
                    amountGroup.netAmount =
                        (amountGroup.netAmount || 0) + (agio.amount || 0);
                    if (agio.vatRate < 0 && agio.vatRate > -6) {
                        amountGroup.vatAmount = 0;
                    } else {
                        let amount = agio.amount * (agioVatRate / 100);

                        let totalAmountGroupVatAmount =
                            (amountGroup.vatAmount || 0) + amount;

                        totalAmountGroupVatAmount =
                            Math.round(totalAmountGroupVatAmount * 100) / 100;

                        amountGroup.vatAmount = totalAmountGroupVatAmount;
                    }
                    insert = false;
                }
            }

            if (insert) {
                let amount = 0;

                if (agio.vatRate === undefined) {
                    amount = 0;
                } else if (agio.vatRate <= 0) {
                    amount = 0;
                } else {
                    amount = ((agio.amount || 0) * (agio.vatRate || 0)) / 100;

                    amount = Math.round(amount * 100) / 100;
                }

                newAmountGroups.push({
                    netAmount: agio.amount,
                    vatRate: agioVatRate,
                    vatAmount: amount,
                });
            }
        }

        newAmountGroups.sort(function (a, b) {
            return (a.vatRate || 0) - (b.vatRate || 0);
        });

        safeUpdateData((prevState) => ({
            ...prevState,
            documentTotalsData: {
                ...prevState.documentTotalsData,
                amountGroups: newAmountGroups,
                sumAllowance: sumAllowance,
                sumCharges: sumCharges,
            },
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data?.positions,
        data?.documentTotalsData?.allowances,
        data?.documentTotalsData?.agios,
        taxCategories,
        t,
    ]);

    useEffect(() => {
        switch (data?.paymentInfo.paymentMeans) {
            case PaymentMeansCode.SepaDirectDebit:
                safeUpdateData((prevState) => ({
                    ...prevState,
                    paymentInfo: {
                        ...prevState.paymentInfo,
                        transfers: [],
                    },
                }));
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.paymentInfo.paymentMeans]);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            try {
                let newInitialData = await XRechnungService.get(id ?? -1);

                if (newInitialData) {
                    if (
                        newInitialData.documentTotalsData.totalVatAmount == null
                    ) {
                        newInitialData.documentTotalsData.totalVatAmount = 0;
                    }

                    if (newInitialData.documentTotalsData.total == null) {
                        newInitialData.documentTotalsData.total = 0;
                    }

                    if (
                        newInitialData.documentTotalsData.sumAllowance == null
                    ) {
                        newInitialData.documentTotalsData.sumAllowance = 0;
                    }

                    if (newInitialData.documentTotalsData.sumCharges == null) {
                        newInitialData.documentTotalsData.sumCharges = 0;
                    }

                    if (
                        newInitialData.receiverData.receiverAddressCountry ===
                        null
                    )
                        newInitialData.receiverData.receiverAddressCountry =
                            undefined;

                    if (newInitialData.positions.length === 0) {
                        newInitialData.positions.push({
                            unit: undefined,
                            vatRate: undefined,
                            surchargeGroups: [],
                            rebateGroups: [],
                            subLines: [],
                            quantity: 0,
                        } as any);
                    } else {
                        // Ensuring valid vat rates
                        newInitialData.positions = newInitialData.positions.map(
                            (position) => {
                                if (position.vatRate && position.vatRate < -6) {
                                    position.vatRate = 0;
                                }

                                return position;
                            }
                        );
                    }

                    if (newInitialData.paymentInfo.paymentMeans === 0) {
                        newInitialData.paymentInfo.paymentMeans =
                            PaymentMeansCode.SepaCreditTransfer;
                    }

                    if (
                        !(newInitialData.paymentInfo.transfers instanceof Array)
                    ) {
                        newInitialData.paymentInfo.transfers = [];
                    }

                    if (
                        !(
                            newInitialData.documentTotalsData
                                .allowances instanceof Array
                        )
                    ) {
                        newInitialData.documentTotalsData.allowances = [];
                    }

                    if (
                        !(
                            newInitialData.documentTotalsData.agios instanceof
                            Array
                        )
                    ) {
                        newInitialData.documentTotalsData.agios = [];
                    }

                    if (newInitialData?.paymentInfo.transfers.length === 0) {
                        newInitialData.paymentInfo.transfers.push({} as any);
                    }

                    //Xrechnung 3.0 removal of delays and adding to freetext
                    if (newInitialData.invoiceData.delays.length > 0) {
                        let paymentTerms =
                            newInitialData.invoiceData.paymentTerms ?? '';

                        newInitialData.invoiceData.delays.forEach((delay) => {
                            paymentTerms += `\n Verzug - Tage:${delay.dueDays} - Prozent:${delay.percentage}% - Basisbetrag:${delay.baseAmount}`;
                        });

                        newInitialData.invoiceData.paymentTerms = paymentTerms;
                        newInitialData.invoiceData.delays = [];
                    }

                    if (!newInitialData.invoiceData.docDate) {
                        newInitialData.invoiceData.docDate = null;
                    } else {
                        newInitialData.invoiceData.docDate = new Date(
                            newInitialData.invoiceData.docDate
                        );
                    }

                    if (!newInitialData.invoiceData.referringInvoiceDate) {
                        newInitialData.invoiceData.referringInvoiceDate = null;
                    } else {
                        newInitialData.invoiceData.referringInvoiceDate =
                            new Date(
                                newInitialData.invoiceData.referringInvoiceDate
                            );
                    }

                    if (!newInitialData.invoiceData.paymentDueDate) {
                        newInitialData.invoiceData.paymentDueDate = null;
                    } else {
                        newInitialData.invoiceData.paymentDueDate = new Date(
                            newInitialData.invoiceData.paymentDueDate
                        );
                    }

                    if (!newInitialData.invoiceData.invoicePeriodStartDate) {
                        newInitialData.invoiceData.invoicePeriodStartDate =
                            null;
                    } else {
                        newInitialData.invoiceData.invoicePeriodStartDate =
                            new Date(
                                newInitialData.invoiceData.invoicePeriodStartDate
                            );
                    }

                    if (!newInitialData.invoiceData.invoicePeriodEndDate) {
                        newInitialData.invoiceData.invoicePeriodEndDate = null;
                    } else {
                        newInitialData.invoiceData.invoicePeriodEndDate =
                            new Date(
                                newInitialData.invoiceData.invoicePeriodEndDate
                            );
                    }

                    if (!newInitialData.receiverData.deliveryDate) {
                        newInitialData.receiverData.deliveryDate = null;
                    } else {
                        newInitialData.receiverData.deliveryDate = new Date(
                            newInitialData.receiverData.deliveryDate
                        );
                    }

                    if (
                        newInitialData.documentTotalsData
                            .amountsPrepaidAmount == null
                    ) {
                        newInitialData.documentTotalsData.amountsPrepaidAmount = 0;
                    }

                    if (
                        newInitialData.documentTotalsData
                            .amountsRoundingAmount == null
                    ) {
                        newInitialData.documentTotalsData.amountsRoundingAmount = 0;
                    }
                }

                if (
                    newInitialData?.paymentInfo?.paymentMeans ===
                    PaymentMeansCode.DebitTransfer
                ) {
                    newInitialData.paymentInfo.paymentMeans =
                        PaymentMeansCode.SepaDirectDebit;
                }

                setInitialData(newInitialData);
                setData(newInitialData);
            } catch (error) {
                const code = RequestTools.getApiErrorCode(error);

                if (code === 404) {
                    setInvoiceNotFound(true);
                } else {
                    Toast.showErrorWhenAuthorized(error);
                }
            }

            setIsLoading(false);
        }

        fetchData();
    }, [id]);

    useEffect(() => {
        async function fetchUnitOptions() {
            try {
                let result = await XRechnungService.getUnitOptions();

                setUnitOptions(result || []);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        }

        async function fetchTaxCategories() {
            try {
                let result = await XRechnungService.getTaxCategories();

                setTaxCategories(result || []);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        }

        const fetchMessageTypes = async () => {
            try {
                let types =
                    (await MessagesService.getXRechnungMessageTypes()) ?? [];
                setMessageTypes(types ?? []);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        };

        const fetchCountries = async () => {
            try {
                let countries = await LocaleService.getCountriesList();

                setCountriesList(countries);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        };

        fetchUnitOptions();
        fetchTaxCategories();
        fetchMessageTypes();
        fetchCountries();
    }, [t]);

    useEffect(() => {
        let hasExempt = !!data?.documentTotalsData.amountGroups.find(
            (group) => group.vatRate === -1
        );

        let exemptLabel = t('XRechnung.TaxExcemptionDefaultReason.Text');

        if (!data?.senderData.senderMicroenterpreneuer) {
            if (hasExempt) {
                safeUpdateData((prevState) => ({
                    ...prevState,
                    documentTotalsData: {
                        ...prevState.documentTotalsData,
                        amountsTaxExemptionReason: exemptLabel,
                    },
                }));
            } else if (
                data?.documentTotalsData.amountsTaxExemptionReason ===
                exemptLabel
            ) {
                safeUpdateData((prevState) => ({
                    ...prevState,
                    documentTotalsData: {
                        ...prevState.documentTotalsData,
                        amountsTaxExemptionReason: '',
                    },
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data?.documentTotalsData.amountGroups,
        t,
        data?.senderData.senderMicroenterpreneuer,
    ]);

    useEffect(() => {
        if (
            data?.invoiceData.invoicePeriodStartDate != null &&
            data?.invoiceData.invoicePeriodEndDate === null
        ) {
            safeUpdateData((prevData) => ({
                ...prevData,
                invoiceData: {
                    ...prevData.invoiceData,
                    invoicePeriodEndDate:
                        data?.invoiceData.invoicePeriodStartDate || null,
                },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.invoiceData.invoicePeriodStartDate]);

    useEffect(() => {
        if (
            data?.invoiceData.invoicePeriodStartDate === null &&
            data?.invoiceData.invoicePeriodEndDate != null
        ) {
            safeUpdateData((prevData) => ({
                ...prevData,
                invoiceData: {
                    ...prevData.invoiceData,
                    invoicePeriodStartDate:
                        data?.invoiceData.invoicePeriodEndDate || null,
                },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.invoiceData.invoicePeriodEndDate]);

    function generateTabs(data: XRechnung | null, isFormSubmitted: boolean) {
        return [
            {
                title: t('XRechnung.InvoiceDataTab.Text'),
                content: (
                    <XRechnungInvoiceData
                        isFormSubmitted={isFormSubmitted}
                        unitOptions={unitOptions}
                        taxCategories={taxCategories}
                        messageTypes={messageTypes}
                        data={data}
                        updateData={safeUpdateData}
                    />
                ),
                value: 'invoice-data',
                icon: 'file-text',
                error: !XRechnungPagesValidator.InvoiceDataValid({
                    data,
                    isFormSubmitted,
                }),
                screenReaderInfo: !XRechnungPagesValidator.InvoiceDataValid({
                    data,
                    isFormSubmitted,
                })
                    ? t('ValidationMessage.ErrorsPresent.Text')
                    : undefined,
            },
            {
                title: t('XRechnung.SenderDataTab.Text'),
                content: (
                    <XRechnungSeller
                        isFormSubmitted={isFormSubmitted}
                        unitOptions={unitOptions}
                        taxCategories={taxCategories}
                        messageTypes={messageTypes}
                        countriesList={countriesList}
                        data={data}
                        updateData={safeUpdateData}
                    />
                ),
                value: 'seller',
                icon: 'arrow-up-right-circle',
                error: !XRechnungSellerValidators.DataValid({
                    data,
                    isFormSubmitted,
                }),
                screenReaderInfo: !XRechnungSellerValidators.DataValid({
                    data,
                    isFormSubmitted,
                })
                    ? t('ValidationMessage.ErrorsPresent.Text')
                    : undefined,
            },
            {
                title: t('XRechnung.ReceiverDataTab.Text'),
                content: (
                    <XRechnungBuyer
                        isFormSubmitted={isFormSubmitted}
                        unitOptions={unitOptions}
                        taxCategories={taxCategories}
                        messageTypes={messageTypes}
                        countriesList={countriesList}
                        data={data}
                        updateData={safeUpdateData}
                    />
                ),
                value: 'buyer',
                icon: 'arrow-down-left-circle',
                error: !XRechnungBuyerValidators.BuyerDataValid({
                    data,
                    isFormSubmitted,
                }),
                screenReaderInfo: !XRechnungBuyerValidators.BuyerDataValid({
                    data,
                    isFormSubmitted,
                })
                    ? t('ValidationMessage.ErrorsPresent.Text')
                    : undefined,
            },
            {
                title: t('XRechnung.PositionsTab.Text'),
                content: (
                    <XRechnungInvoiceLineItems
                        isFormSubmitted={isFormSubmitted}
                        unitOptions={unitOptions}
                        taxCategories={taxCategories}
                        messageTypes={messageTypes}
                        data={data}
                        updateData={safeUpdateData}
                        senderMicroenterpreneuer={
                            data?.senderData.senderMicroenterpreneuer || false
                        }
                    />
                ),
                value: 'line-items',
                icon: 'list-nested',
                error: !XRechnungPagesValidator.InvoiceLineItemsDataValid({
                    data,
                    isFormSubmitted,
                }),
                screenReaderInfo:
                    !XRechnungPagesValidator.InvoiceLineItemsDataValid({
                        data,
                        isFormSubmitted,
                    })
                        ? t('ValidationMessage.ErrorsPresent.Text')
                        : undefined,
            },
            {
                title: t('XRechnung.DocumentTotalsTab.Text'),
                content: (
                    <XRechnungPaymentTotals
                        isFormSubmitted={isFormSubmitted}
                        unitOptions={unitOptions}
                        taxCategories={taxCategories}
                        messageTypes={messageTypes}
                        data={data}
                        updateData={safeUpdateData}
                    />
                ),
                value: 'payment-totals',
                icon: 'currency-euro',
                error: !XRechnungPagesValidator.PaymentTotalsDataValid({
                    data,
                    isFormSubmitted,
                }),
                screenReaderInfo:
                    !XRechnungPagesValidator.PaymentTotalsDataValid({
                        data,
                        isFormSubmitted,
                    })
                        ? t('ValidationMessage.ErrorsPresent.Text')
                        : undefined,
            },
            {
                title: t('XRechnung.PaymentInfoTab.Text'),
                content: (
                    <XRechnungPaymentMeans
                        isFormSubmitted={isFormSubmitted}
                        unitOptions={unitOptions}
                        taxCategories={taxCategories}
                        messageTypes={messageTypes}
                        data={data}
                        updateData={safeUpdateData}
                    />
                ),
                value: 'payment-means',
                icon: 'bank',
                error: !XRechnungPagesValidator.PaymentMeansDataValid({
                    data,
                    isFormSubmitted,
                }),
                screenReaderInfo:
                    !XRechnungPagesValidator.PaymentMeansDataValid({
                        data,
                        isFormSubmitted,
                    })
                        ? t('ValidationMessage.ErrorsPresent.Text')
                        : undefined,
            },
            {
                title: t('XRechnung.AttachmentTab.Text'),
                content: (
                    <XRechnungAttachments
                        isFormSubmitted={isFormSubmitted}
                        unitOptions={unitOptions}
                        taxCategories={taxCategories}
                        messageTypes={messageTypes}
                        data={data}
                        updateData={safeUpdateData}
                    />
                ),
                value: 'attachments',
                icon: 'paperclip',
                error: !XRechnungPagesValidator.AttachmentsValid({
                    data,
                    isFormSubmitted,
                }),
                screenReaderInfo: !XRechnungPagesValidator.AttachmentsValid({
                    data,
                    isFormSubmitted,
                })
                    ? t('ValidationMessage.ErrorsPresent.Text')
                    : undefined,
            },
            {
                title: t(
                    appSettings?.bigAttachmentsEnabled
                        ? 'XRechnung.ExternalReferencesTabBigAttachments.Text'
                        : 'XRechnung.ExternalReferencesTab.Text'
                ),
                content: (
                    <XRechnungReferences
                        isFormSubmitted={isFormSubmitted}
                        unitOptions={unitOptions}
                        taxCategories={taxCategories}
                        messageTypes={messageTypes}
                        data={data}
                        updateData={safeUpdateData}
                    />
                ),
                value: 'references-attachments',
                icon: 'link-45deg',
                error: !XRechnungPagesValidator.ReferencesValid({
                    data,
                    isFormSubmitted,
                }),
                screenReaderInfo: !XRechnungPagesValidator.ReferencesValid({
                    data,
                    isFormSubmitted,
                })
                    ? t('ValidationMessage.ErrorsPresent.Text')
                    : undefined,
            },
            {
                title: t('XRechnung.SummaryTab.Text'),
                content: (
                    <XRechnungSummary
                        isFormSubmitted={isFormSubmitted}
                        unitOptions={unitOptions}
                        taxCategories={taxCategories}
                        messageTypes={messageTypes}
                        countriesList={countriesList}
                        data={data}
                        updateData={safeUpdateData}
                    />
                ),
                value: 'summary',
                icon: 'eye',
            },
        ];
    }

    async function saveDocument() {
        autofillInvoicePeriod();
        setIsLoading(true);

        try {
            if (data) {
                let response = await XRechnungService.save(data);
                Toast.success(response.message);
            }
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        setIsLoading(false);
    }

    async function downloadInvoice(ignoreAttachments: boolean) {
        autofillInvoicePeriod();
        if (!data) {
            return;
        }

        setIsLoading(true);

        try {
            await XRechnungService.export(data, ignoreAttachments);
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        setIsLoading(false);
    }

    function exportDocument(e: React.MouseEvent<HTMLButtonElement>) {
        if (data && data.attachments.length === 0) {
            // if no attachments it is more perfomant to include attachments
            downloadInvoice(false);
        } else {
            if (e.target instanceof HTMLButtonElement) {
                setModalCallerElement(e.target);
            }

            AnimationTools.activateModal(setIsDownloadInvoiceModalActive);
        }
    }

    function autofillInvoicePeriod() {
        if (
            data?.invoiceData.invoicePeriodStartDate != null &&
            data?.invoiceData.invoicePeriodEndDate === null
        ) {
            safeUpdateData((prevData) => ({
                ...prevData,
                invoiceData: {
                    ...prevData.invoiceData,
                    invoicePeriodEndDate:
                        data?.invoiceData.invoicePeriodStartDate || null,
                },
            }));
        }

        if (
            data?.invoiceData.invoicePeriodStartDate === null &&
            data?.invoiceData.invoicePeriodEndDate != null
        ) {
            safeUpdateData((prevData) => ({
                ...prevData,
                invoiceData: {
                    ...prevData.invoiceData,
                    invoicePeriodStartDate:
                        data?.invoiceData.invoicePeriodEndDate || null,
                },
            }));
        }
    }

    async function updateAndValidateDocument() {
        setIsFormSubmitted(true);

        autofillInvoicePeriod();

        let newTabs = generateTabs(data, true);

        let finded = false;

        newTabs.forEach((tab) => {
            if (tab.error && !finded) {
                navigate('/dashboard/xrechnung/' + id + '/' + tab.value);
                finded = true;
                return false;
            }
        });

        if (!finded) {
            setIsLoading(true);

            try {
                if (data) {
                    let response = await XRechnungService.updateAndValidate(
                        data
                    );

                    if (response) {
                        Toast.success(response.message);
                    }
                }
            } catch (error: any) {
                if (
                    error.response &&
                    error.response.data?.data === 'SchematronError'
                ) {
                    const schematronErrors = error.response.data
                        ?.details as string[];
                    setSchematronErrors(schematronErrors);
                    AnimationTools.activateModal(
                        setSchematronErrorsDialogActive
                    );
                } else {
                    Toast.showErrorWhenAuthorized(error);
                }
            }

            setIsLoading(false);
        } else {
            setTimeout(() => {
                let firstInvalidElement = document.querySelector(
                    '[aria-invalid=true]'
                );

                if (
                    firstInvalidElement instanceof HTMLInputElement ||
                    firstInvalidElement instanceof HTMLSelectElement ||
                    firstInvalidElement instanceof HTMLTextAreaElement
                ) {
                    firstInvalidElement.focus();
                }
            }, 300);
        }
    }

    async function submitDocument() {
        autofillInvoicePeriod();
        setIsFormSubmitted(true);

        let newTabs = generateTabs(data, true);

        let finded = false;

        newTabs.forEach((tab) => {
            if (tab.error && !finded) {
                navigate('/dashboard/xrechnung/' + id + '/' + tab.value);
                finded = true;
                return false;
            }
        });

        if (!finded) {
            setIsLoading(true);

            try {
                if (data) {
                    let response = await XRechnungService.submit(data);

                    if (response) {
                        Toast.success(response.message);
                        navigate('/dashboard/messages');
                    }
                }
            } catch (error: any) {
                if (
                    error.response &&
                    error.response.data?.data === 'SchematronError'
                ) {
                    const schematronErrors = error.response.data
                        ?.details as string[];
                    setSchematronErrors(schematronErrors);
                    AnimationTools.activateModal(
                        setSchematronErrorsDialogActive
                    );
                } else {
                    Toast.showErrorWhenAuthorized(error);
                }
            }

            setIsLoading(false);
        } else {
            setTimeout(() => {
                let firstInvalidElement = document.querySelector(
                    '[aria-invalid=true]'
                );

                if (
                    firstInvalidElement instanceof HTMLInputElement ||
                    firstInvalidElement instanceof HTMLSelectElement ||
                    firstInvalidElement instanceof HTMLTextAreaElement
                ) {
                    firstInvalidElement.focus();
                }
            }, 300);
        }
    }

    let tabs: ITab[] = generateTabs(data, isFormSubmitted);

    const mainUI = (
        <div className='xrechnung' ref={ref}>
            <div className='xrechnung-common-actions'>
                <Button
                    danger={true}
                    icon={'arrow-repeat'}
                    onClick={() => {
                        setData(initialData);
                        Toast.success(
                            t('XRechnung.XRechnungSuccessfullyReset.Text')
                        );
                    }}
                    title={t('Setup.ClearDataBtn.Text')}
                    aria-label={t('Setup.ClearDataBtn.Text')}
                    size={'mini'}
                />
                <Button
                    primary={false}
                    icon={'download'}
                    title={t('Setup.ExportBtn.Text')}
                    aria-label={t('Setup.ExportBtn.Text')}
                    size={'mini'}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        exportDocument(e);
                    }}
                />
                <Button
                    primary={true}
                    icon={'save'}
                    onClick={() => saveDocument()}
                    text={t('Setup.SaveDataBtn.Text')}
                />
                <Button
                    primary={true}
                    icon={'check-lg'}
                    onClick={() => updateAndValidateDocument()}
                    text={t('Setup.UpdateAndValidateBtn.Text')}
                />
                <Button
                    primary={true}
                    icon={'send'}
                    onClick={() => submitDocument()}
                    text={t('XRechnung.SubmitData.Text')}
                />
                <Button
                    size='mini'
                    danger={true}
                    icon={'x-lg'}
                    title={t('Setup.Cancel.Text')}
                    className={'cancel-button'}
                    isLink={true}
                    linkTo={'/dashboard/messages'}
                />
            </div>

            {data && (
                <TabbedView
                    onChange={(newValue: number | string) => {
                        navigate('/dashboard/xrechnung/' + id + '/' + newValue);
                    }}
                    value={activeTab}
                    items={tabs}
                    vertical={!(windowSize !== null && windowSize.width < 900)}
                    showNavigation={true}
                    lastTabButton={{
                        primary: true,
                        icon: 'send',
                        onClick: () => submitDocument(),
                        text: t('XRechnung.SubmitData.Text'),
                        className: 'action-button',
                    }}
                />
            )}

            <DownloadInvoiceModal
                active={isDownloadInvoiceModalActive}
                onClose={(response: DownloadInvoiceModelResponse) => {
                    if (
                        response ===
                        DownloadInvoiceModelResponse.WithAttachments
                    ) {
                        downloadInvoice(false);
                    } else if (
                        response ===
                        DownloadInvoiceModelResponse.WithoutAttachments
                    ) {
                        downloadInvoice(true);
                    }

                    AnimationTools.deactivateModal(
                        setIsDownloadInvoiceModalActive,
                        () => {
                            findAndFocusModalCallerElement(
                                modalCallerElement,
                                () => {
                                    setModalCallerElement(null);
                                }
                            );
                        }
                    );
                }}
            />

            <SchematronValidationErrorDialog
                active={schematronErrorsDialogActive}
                schematronErrors={schematronErrors}
                onClose={() => {
                    AnimationTools.deactivateModal(
                        setSchematronErrorsDialogActive,
                        () => {
                            setSchematronErrors([]);
                        }
                    );
                }}
            />

            <LoadingOverlay
                loadingMessage={t('Common.LoadingMessage.Text')}
                active={isLoading}
            />
        </div>
    );

    return (
        <>
            {invoiceNotFound ? (
                <div className='invoice-not-found'>
                    <div className='fade-in'>
                        <BootstrapIcon
                            className='big-icon'
                            icon='file-earmark-break'
                        />
                        <h1>{t('Messages.NotFound.Tooltip')}</h1>
                        <p>{t('Messages.NotFound.Text')}</p>
                        <Button
                            primary={true}
                            icon='chevron-left'
                            text={t('Messages.BackToList.Text')}
                            isLink={true}
                            linkTo={'/dashboard/messages'}
                        />
                    </div>
                </div>
            ) : (
                mainUI
            )}
        </>
    );
}
